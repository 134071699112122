.container {
  background-image: url("./assets/bg.png");
  background-repeat: no-repeat;
  background-color: black;
  background-size: contain;
  background-position: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 100%;
}

.email {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  margin-top: 2rem;

  a {
    color: rgb(123, 75, 187);
  }
}

@media only screen and (max-width: 900px) {
  .container {
    background-size: cover;
    background-position: center;
  }

  .logo {
    padding: 2rem;
  }

  .logo img {
    width: 100%;
    text-align: center;
  }

  .email {
    margin-top: 1rem;
  }
}
